.episodeMedia {
  &--icon {
    cursor: pointer;
  }

  /* Dislay Episode PA Icon upon hover/focus only, when user device:
  1. Has accurate input pointing device (e.g.: mouse, touchpad)
  2. Is TV */
  @media (pointer: fine) {
    &--overlay-icon-conditional {
      display: none;
    }

    button:enabled:hover &--overlay-icon-conditional,
    button:enabled:focus &--overlay-icon-conditional,
    a:hover &--overlay-icon-conditional,
    a:focus &--overlay-icon-conditional {
      display: flex;
    }
  }
}

.episodeMedia--on-tv {
  .episodeMedia--overlay-icon-conditional {
    display: none;
  }
}

:global(.tv__focus) {
  .episodeMedia--on-tv .episodeMedia--overlay-icon-conditional {
    display: flex;
  }
}
