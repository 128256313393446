@import '../../../../../../../themes/_colors.css';
@import '../../../../../../../themes/_variables.css';

.episode-editorial__header {
  flex: auto;
  align-self: center;
  width: calc(
    100% - var(--image-max-width) - var(--d2g-max-width) -
      var(--margin-horizontal) * 2
  );
  margin-left: 0.5rem;

  @media (--mq-from-tablet) {
    flex: none;
    align-self: unset;
  }
}

.episode-editorial__header,
.episode-editorial__body {
  max-width: 100rem;

  @media (--mq-from-tablet) {
    margin-right: calc(var(--d2g-max-width) + var(--margin-horizontal));
    margin-left: calc(var(--image-max-width) + var(--margin-horizontal));
  }

  @mixin tvDevice {
    width: auto;
    max-width: calc(100% - var(--margin-horizontal-tv) * 2);
    margin-right: 1.25rem;
    margin-left: 1.25rem;
  }
}

.episode-editorial__title {
  margin: 0;
  font-family: var(--font_hind);
  font-size: 1.125rem;
  line-height: 1.375rem;
  color: var(--color_white);
  /* Truncate the editorial title text after 3 lines to have a nice display on mobile */
  @mixin lineClamp 3, 1.375;

  @media (--mq-from-tablet) {
    margin-top: 0.5rem;
    font-size: 1.375rem;
    line-height: 1.625rem;
  }

  @mixin tvDevice {
    /* To display the text with ellipsis in one line on TV device, we need to override the display: -webkit-box generated by `lineClamp` mixin */
    display: inherit;
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
}

.episode-editorial__duration {
  font-size: 1rem;
  line-height: 1.25rem;
  color: var(--color_white-50);

  @media (--mq-from-tablet) {
    margin-bottom: 0.5625rem;
  }

  @mixin tvDevice {
    margin-bottom: 1.5rem;
    font-size: 1.6875rem;
    line-height: 2rem;
    white-space: break-spaces;
  }
}

.episode-editorial__broadcast-date-label {
  max-width: 10rem;
  margin: 0;
  @media (--mq-from-tablet) {
    max-width: 100%;
    margin-bottom: 0.5625rem;
  }

  /* Broadcast subtypes: */
  &--rental {
    /* E18 PlayFromLocInMoneyTime */
    color: var(--color_information-label-rental-fg);
  }
  &--withDescription {
    /* E20-a PlayFromLoc */
    color: var(--color_white-50);
  }
}

.episode-editorial__D2G {
  align-self: center;
  height: 100%;

  @media (--mq-from-tablet) {
    position: absolute;
    top: 0.625rem;
    right: 0;
    height: unset;
  }
}

.episode-editorial__body {
  margin-top: 1rem;

  @media (--mq-from-tablet) {
    margin-top: 0;
  }
}

.episode-editorial__editorial-title {
  font-family: var(--font_hind);
  font-weight: 600;
  color: var(--color_white);
}

.episode-editorial__editorial-title,
.episode-editorial__summary {
  font-size: 1rem;
  line-height: 1.25rem;

  @mixin tvDevice {
    font-size: 1.6875rem;
    line-height: 2rem;
    white-space: break-spaces;
  }
}

.episode-editorial__summary {
  max-width: 47.5rem;
  margin: 0 0 0.5rem 0;
  color: var(--color_white-80);
  white-space: normal;

  @media (--mq-from-tablet) {
    margin-bottom: 0.25rem;
  }

  @mixin tvDevice {
    margin-bottom: 1rem;
  }
}

.episode-editorial__broadcast-date-label,
.episode-editorial__availability-end-date-label {
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: var(--color_white-50);

  @mixin tvDevice {
    font-size: 1.6875rem;
    line-height: 2rem;
  }
}
