@import '../../../../../themes/_colors.css';
@import '../../../../../themes/_variables.css';

.episodesList {
  &__list {
    padding: 0 3.125rem;
    margin: 1.25rem 0 0 0;
  }

  &__seasonsTabs {
    @mixin tvDevice {
      padding-top: 1.375rem;
      padding-left: 5.625rem;
    }
  }
}
