.episodesContainer {
  min-height: 100vh;
  margin: 1rem 0 0 0;

  &--detailV5 {
    padding-bottom: calc(3rem + env(safe-area-inset-bottom, 0));
  }

  @mixin tvDevice {
    padding: 0;
    margin-top: 2.125rem;
    --HZL-list-side-padding: 4rem;
  }

  .spinner__content {
    position: relative;
    height: 10rem;
  }

  /* Intersection Observer sentinel.
  It triggers a fetch of the next page of Episodes when it enters the viewport.
  For improved UX, it is placed before the end of the Episodes list */
  &__sentinel-wrapper-tv {
    position: relative;
  }

  &__sentinel-tv {
    position: absolute;
    top: 0;
    /* Placed 4 episodes-away HORIZONTALLY from last episode */
    right: 141.5rem;
  }

  &__sentinel-web {
    /* Placed 4 episodes-away VERTICALLY from last episode */
    transform: translateY(-48rem);
  }
}
