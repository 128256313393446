@import '../../../../../../../themes/_colors.css';
@import '../../../../../../../themes/_variables.css';

.episode {
  --color_information-label-rental-fg: hsl(357.9, 81.1%, 58.4%); /* EB3F45 */
  --image-max-width: 10rem;
  --margin-horizontal: 1.25rem;
  --d2g-max-width: 1.75rem;
  --margin-horizontal-tv: 1.625rem;

  @media (--mq-from-tablet) {
    --image-max-width: 18.4375rem;
  }

  display: flex;
  flex-flow: row wrap;
  width: 100%;
  margin-bottom: 1.5625rem;
  color: var(--color_white);

  @media (--mq-from-tablet) {
    position: relative;
    flex-flow: column wrap;
    min-height: 10.4375rem;
  }

  @mixin tvDevice {
    display: inline-flex;
    width: 33.33333%;
    vertical-align: top;
  }

  /**
   * Allows to keep horizontal alignment on one-discovery when reaching the end of the list
   */
  &:nth-last-child(2) {
    @mixin tvDevice {
      margin-right: 6.0625rem;
    }
  }

  &__button {
    &--unavailable {
      cursor: default;
    }
    flex: var(--image-max-width);

    max-width: var(--image-max-width);

    @media (--mq-from-tablet) {
      position: absolute;
      top: 0;
      left: 0;
      width: var(--image-max-width);
      max-width: var(--image-max-width);
    }

    @mixin tvDevice {
      position: inherit;
      width: auto;
      max-width: 100%;
      margin: var(--margin-horizontal-tv);
      border-radius: 0.25rem;
    }
  }
}
